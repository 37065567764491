/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface NameMeta {
  name: string;
  content: string;
}

interface PropertyMeta {
  property: string;
  content: string;
}

type Meta = NameMeta | PropertyMeta;

interface Props {
  description: string;
  lang: string;
  meta: Meta;
  title: string;
}

export const fluidImage =
  graphql`
    fragment fluidImage on File {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `
;

const Seo = ({ description, lang, meta, title }: Props) => {



  const { site, skillgoLogo } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            siteDomain    
            social {
              twitter
            }
          }
        }

        skillgoLogo: file (
          relativePath: { eq: "skillgo-logo.png" }
        ) {
          ...fluidImage
        }
    
      }
    `
  );



  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const siteAbsoluteURL = site.siteMetadata.siteUrl
  const imageAbsoluteURL = site.siteMetadata.siteDomain + skillgoLogo.childImageSharp.fluid.src;
  
  const metaTitle = title ? title : defaultTitle

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: imageAbsoluteURL,
        },
        {
          property: `og:url`,
          content: siteAbsoluteURL,
        },
        {
          property: `og:site_name`,
          content: `Skillgo`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.social?.twitter || ``,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
};

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
